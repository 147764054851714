import { useRef } from "react";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const LoadingAnimation = () => {
    const container = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        gsap.set('svg', { visibility: 'visible' });

        let timeLine = gsap.timeline({repeat: -1, yoyo: true, defaults: {
            ease: 'sine.inOut',
            duration: 1.2
        }});
        timeLine
        .fromTo('#gradDot', { x: 90 }, { x: -90 })
        .fromTo('#fillDot', { x: -90 }, { x: 90 }, 0)
        .fromTo(
            '#mainGrad', 
            {
                attr: {
                    cx:230,
                    fx:230
                }
            }, 
            {
                attr: {
                    cx: 570,
                    fx: 570
                }
            }, 
            0
        );
    });
    
    return <div ref={container} className={styles.LoadingAnimation}>
        <svg id="mainSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
            <defs>
                    <radialGradient id="mainGrad" cx="400" cy="300" fx="400" fy="300" r="100" gradientUnits="userSpaceOnUse">
                <stop offset=".68" stop-color="#6c3ebd"/>
                  <stop offset=".72" stop-color="#7F49DE"/>
                  <stop offset=".77" stop-color="#8c58e6"/>
                  <stop offset=".82" stop-color="#9668e6"/>
                  <stop offset=".88" stop-color="#a078e6"/>
                  <stop offset=".93" stop-color="#ad89ec"/>
                  <stop offset=".99" stop-color="#b89ce9"/>
                  <stop offset="1" stop-color="#c2aaec"/>
                </radialGradient>
                </defs>
            <circle id="fillDot" cx="400" cy="300" fill="#7F49DE" r="100" />
            <circle id="gradDot" cx="400" cy="300" fill="url(#mainGrad)" r="100" />
        </svg>
    </div>;
}

export default LoadingAnimation;
