import { Button } from 'antd';
import React, { ReactElement } from 'react';
import styles from './styles.module.scss';

interface CustomButtonProps {
  isPrimal?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactElement;
  htmlType?: 'submit' | 'button';
  disabled?: boolean;
}

export const CustomButton = ({
  isPrimal = false,
  htmlType,
  onClick,
  children,
  className = '',
  disabled = false,
}: CustomButtonProps) => (
  <Button
    htmlType={htmlType}
    className={`${isPrimal ? styles.PrimalButton : styles.SecondaryButton} ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
);
