import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import styles from './styles.module.scss';

const CallFailedNote = () => {
    return (
        <p className={styles.NoteWrapper}>
            <LoadingAnimation />
            <p className={styles.Title}>Соединение не установлено</p>
            <p className={styles.NoteParagraph}>Вероятно, пациент еще не в сети.<br/>Позвоните позже или напишите в чат.<br/>
            Если пациент не отвечает длительное время - вы можете отменить консультацию и списать либо вернуть ему оплату по своему усмотрению</p>
        </p>
    );
}



export default CallFailedNote;