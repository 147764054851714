//ДОСТАЕМ ИЗ ССЫЛКИ

import {  useParams,  useSearchParams } from "react-router-dom";

const useHashOneTimeKey = () => {
  const {UUID} = useParams();
  const [urlParams] = useSearchParams();
  const sessionId = urlParams.get('sessionId')


  return { UUID, sessionId };
};

export default useHashOneTimeKey;
