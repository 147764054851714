import React, { useEffect, useState } from "react";
import { Call } from "voximplant-websdk/Call/Call";

type CallDurationProps = {
	currentCall: Call | null | undefined;
	handleCancelCall: () => void;
};

const Timer: React.FC<CallDurationProps> = ({ currentCall, handleCancelCall }) => {
	const [timer, setTimer] = useState<string>("00:00:00");
	const callDuration = parseInt(window.__RUNTIME_CONFIG__.REACT_APP_CALL_DURATION || "0", 10);

	useEffect(() => {
		const interval = setInterval(() => {
			const duration = currentCall?.getCallDuration() || 0;
			const dateTime = new Date(duration);
			setTimer(dateTime.toLocaleTimeString("ru", { timeZone: "UTC" }));
			if (duration >= callDuration) {
				handleCancelCall();
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [currentCall]);

	return (
		<div>
			<p>{timer}</p>
		</div>
	);
};

export default Timer;
