import React, { useState, useEffect } from "react";


const Countdown: React.FC<{ startDateString: string | undefined }> = ({ startDateString }) => {
   

	const startDate2 = startDateString ? new Date(startDateString).getTime() : 0;
  const [countdownText, setCountdownText] = useState("");
  const [consultationStarted, setConsultationStarted] = useState(false);

  useEffect(() => {
    const getTimerText = (startDate2:number) => {
      const now = new Date().getTime();
      const distance = startDate2 - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return `ДО НАЧАЛА: ${String(days).padStart(2, "0")}:${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
      } else {
        return "Консультация началась";
      }
    };

    const interval = setInterval(() => {
      setCountdownText(getTimerText(startDate2)); 
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate2]);

  return <div>{countdownText}</div>;
};

export default Countdown;
