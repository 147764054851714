import React, { useState } from "react";
import { Call } from "voximplant-websdk/Call/Call";
import micro from "../assets/micro.svg";
import micro_disable from "../assets/micro_disable.svg";
import cam from "../assets/cam.svg";
import cam_disable from "../assets/cam_disable.svg";
import fullscreen from "../assets/fullscreen.svg";
import finish_call from "../assets/finish_call.svg";
import share from "../assets/share.svg";
import not_share from "../assets/not_share.svg";
import share_disable from "../assets/share_disable.svg";
import {
	micDisable,
	micEnable,
	cameraDisable,
	cameraEnable,
	handleFullScreenButtonClick,
} from "../ButtonHandlers";
import { CustomButton } from "./CustomButton";
import styles from "../components/styles.module.scss";

import { shareEnable, shareDisable } from "../ButtonHandlers";
import Timer from "./Timer";

type ControlsProps = {
	showLocalVideo: (flag: boolean) => void;
	sendVideo: (flag: boolean) => void;
	currentCall: Call | null | undefined;
	handleCancelCall: () => void;
	micState: boolean;
	setMicState: Function;
	createCall: () => void;
	isPatient: boolean;
	callStatus: "active" | "failed" | "start" | "hangup";
	isMicrophoneAccessGranted:boolean | null;
	isCameraAccessGranted:boolean | null;
};

const Controls: React.FC<ControlsProps> = ({
	micState,
	setMicState,
	showLocalVideo,
	sendVideo,
	currentCall,
	handleCancelCall,
	createCall,
	isPatient,
	callStatus,
	isMicrophoneAccessGranted,
	isCameraAccessGranted
}) => {
	// const [mic, setMic] = useState<boolean>(true);
	const [camera, setCamera] = useState<boolean>(true);
	const [sharing, setSharing] = useState<boolean>(false);

	return (
		<>
			<div id="controls">
				<CustomButton
					className={styles.Button}
					isPrimal={false}
					onClick={() =>
						micState ? micDisable(currentCall, setMicState) : micEnable(currentCall, setMicState)
					}
				>
					{micState ? (
						<img className="micro_enable" src={micro} alt="main" />
					) : (
						<img className="micro_disable" src={micro_disable} alt="main" />
					)}
				</CustomButton>

				<CustomButton
					className={styles.Button}
					isPrimal={false}
					onClick={() =>
						camera
							? cameraDisable(showLocalVideo, sendVideo, setCamera)
							: cameraEnable(showLocalVideo, sendVideo, setCamera)
					}
				>
					{camera ? (
						<img className="cam_enable" src={cam} alt="main" />
					) : (
						<img className="cam_disable" src={cam_disable} alt="main" />
					)}
				</CustomButton>

				<CustomButton
					className={`${styles.Button} ${styles.HiddenOnMobile}`}
					isPrimal={false}
					onClick={() =>
						currentCall
							? sharing
								? shareDisable(currentCall, setSharing)
								: shareEnable(currentCall, setSharing)
							: null
					}
				>
					{currentCall ? (
						sharing ? (
							<img className="share" src={share} alt="main" />
						) : (
							<img className="share_disable" src={share_disable} alt="main" />
						)
					) : (
						<img className="not_share" src={not_share} alt="main" />
					)}
				</CustomButton>

				<CustomButton
					className={`${styles.Button} ${styles.HiddenOnMobile}`}
					isPrimal={false}
					onClick={handleFullScreenButtonClick}
				>
					<img className="fullscreen" src={fullscreen} alt="main" />
				</CustomButton>
			</div>

			{currentCall && (
				<div className="duration_mobile">
					<Timer currentCall={currentCall} handleCancelCall={handleCancelCall} />
				</div>
			)}

<>
    {currentCall ? (
        <div className="hang_call">
            <CustomButton className={styles.Button} isPrimal={false} onClick={handleCancelCall}>
                <img className="finish_call" src={finish_call} alt="main" />
            </CustomButton>
        </div>
    ) : (!isPatient && (isMicrophoneAccessGranted && isCameraAccessGranted)) ? (
        <CustomButton className={styles.Button} isPrimal={true} onClick={createCall}>
            <div className="call_btn">Позвонить пациенту</div>
        </CustomButton>
    ) : (!isPatient && (!isMicrophoneAccessGranted || !isCameraAccessGranted)) ? (
        <CustomButton disabled className={styles.Button} isPrimal={true}>
            <div className="call_btn_disabled">Позвонить пациенту</div>
        </CustomButton>
    ) : null}
</>
			
		</>
	);
};

export default Controls;
