import { ReactElement, useEffect, useState } from "react";
import MobileChat from "./MobileChat/MobileChat";
import styles from "./styles.module.scss";

const widgetUrl: string =
  window.__RUNTIME_CONFIG__.REACT_APP_TM_COMMUNICATION_URL;

interface TMKChatWidgetProps {
  userId?: string;
  roomId: string;
  sessionId: string;
  name?: string;
  tmkHumanFriendlyId: string;
}

export const TMKChatWidget = ({
  userId,
  roomId,
  sessionId,
  name = "TMCommunication",
  tmkHumanFriendlyId
}: TMKChatWidgetProps): ReactElement<any, any> => {
  const scriptId = `micro-frontend-script-${name}`;
  const styleId = `micro-frontend-style-${name}`;
  const link = `${widgetUrl}/?roomId=${roomId}&sessionId=${sessionId}`;

  const [isListenerAdd, setListenerAdd] = useState<boolean>(false);
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true);

  const renderMicroFrontend = (name: string) => {
    // @ts-ignore
    window[`render${name}`](`${name}-container`);
  };

  const getUrl = (val: string) => {
    let url = val;
    try {
      url = new URL(val)?.href;
    } catch {
      try {
        url = new URL(`${window.location.origin}${val}`)?.href;
      } catch {
        /* empty */
      }
    }

    url = url.split("?")[0];

    return url;
  };

  const listener = () => {
    try {
      window?.postMessage(
        {
          isActualCommunicationData: true,
          path: link, // url где развёрнут вкс
          data: {
            route: "messenger",
            userId, //идентификатор пользователя
            showChats: false,
            externalRoomId: roomId, // идентификатор комнаты
            link, // url где развёрнут вкс
            sessionId, //идентификатор сессии если используется tm.auth                   }
          },
        },
        "*"
      );
    } catch (e) {
      console.error(e);
    }
    setListenerAdd(true);
  };

  const configScriptId = `micro-frontend-config-script-${name}`;

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      renderMicroFrontend(name);
      return;
    }

    const url = getUrl(link || "");
   
    if (isFirstLoad && isListenerAdd) {
      fetch(`${url}asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          const scriptConfig = document.createElement("script");
          scriptConfig.src = `${url}config.js`;
          scriptConfig.id = configScriptId;
          scriptConfig.crossOrigin = "";

         
          scriptConfig.onload = () => {
            const script = document.createElement("script");
            script.id = scriptId;
            script.crossOrigin = "";
            const hostElements = url.split("/");
            const srcElements: string[] = [];
            manifest.files["main.js"]?.split("/")?.forEach((f: string) => {
              if (!hostElements.includes(f)) {
                srcElements.push(f);
              }
            });
            script.src = `${url}/${srcElements.join("/")}`;
            script.onload = () => {
              renderMicroFrontend(name);
            };
            document.head.appendChild(script);

            if (manifest.files["main.css"]) {
              const link = document.createElement("link");
              link.rel = "stylesheet";
              const hrefElements: string[] = [];
              manifest.files["main.css"]?.split("/")?.forEach((f: string) => {
                if (!hostElements.includes(f)) {
                  hrefElements.push(f);
                }
              });
              link.href = `${url}/${hrefElements.join("/")}`;
              link.id = styleId;
              document.head.appendChild(link);
            }
          };

          document.head.appendChild(scriptConfig);
        })
        .catch((error) => console.warn(error));
      setFirstLoad(false);
    }
    // if (isFirstLoad && isListenerAdd) {
    //   fetch(`${url}asset-manifest.json`)
    //     .then((res) => {
    //       const scriptConfig = document.createElement("script");
    //       scriptConfig.src = `${url}config.js`;
    //       scriptConfig.id = configScriptId;
    //       scriptConfig.crossOrigin = "";
    //       document.head.appendChild(scriptConfig);

    //       return res.json();
    //     })
    //     .then((manifest) => {
    //       const script = document.createElement("script");
    //       script.id = scriptId;
    //       script.crossOrigin = "";
    //       const hostElements = url.split("/");
    //       const srcElements: string[] = [];
    //       manifest.files["main.js"]?.split("/")?.forEach((f: string) => {
    //         if (!hostElements.includes(f)) {
    //           srcElements.push(f);
    //         }
    //       });
    //       script.src = `${url}/${srcElements.join("/")}`;
    //       script.onload = () => {
    //         renderMicroFrontend(name);
    //       };
    //       document.head.appendChild(script);

    //       if (manifest.files["main.css"]) {
    //         const link = document.createElement("link");
    //         link.rel = "stylesheet";
    //         const hrefElements: string[] = [];
    //         manifest.files["main.css"]?.split("/")?.forEach((f: string) => {
    //           if (!hostElements.includes(f)) {
    //             hrefElements.push(f);
    //           }
    //         });
    //         link.href = `${url}/${hrefElements.join("/")}`;
    //         link.id = styleId;
    //         document.head.appendChild(link);
    //       }
    //     })
    //     .catch((error) => console.warn(error));
    //   setFirstLoad(false);
    // }

    return () => {
      // @ts-ignore
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
      const styleNode = document.getElementById(styleId);
      const scriptNode = document.getElementById(scriptId);

      if (styleNode && styleNode.parentNode === document.head) {
        document.head.removeChild(styleNode);
      }

      if (scriptNode && scriptNode.parentNode === document.head) {
        document.head.removeChild(scriptNode);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListenerAdd]);

  useEffect(() => {
    listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, roomId, sessionId]);

  const loadIframe = ({ data }: { data: string }) => {
    if (data === "communicationLoad") {
      listener();
    }
  };

  useEffect(() => {
    if (!isFirstLoad) listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoad]);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", loadIframe, false);
    }
    return () => window.removeEventListener("message", loadIframe, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openChat = () => {
    const chatElement = document.getElementById(`${name}-container`);
    const chatMobileBtnElement = document.getElementById("chat-mobile-btn");

    if (chatElement && chatMobileBtnElement) {
      chatElement.style.display = "block";
      chatMobileBtnElement.style.display = "none";
    }
  };

  return (
    <>
      <MobileChat openChat={openChat} tmkHumanFriendlyId={tmkHumanFriendlyId}/>
      <main
        id={`${name}-container`}
        className={styles.TMKFrontentdMobile}
        style={{ width: "100%", height: window.screen.availWidth < 920 ? window.screen.availHeight - 200 : "100%" }}
      />
    </>
  );
};
