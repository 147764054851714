import { Call } from "voximplant-websdk/Call/Call";


export function onCallConnected(
	currentCall: Call | null | undefined,
	setShowCancelButton: Function,
	sendVideo: Function,
	showRemoteVideo: Function,
	setCallStatus: Function,
	
) {
	console.log({ currentCall });

	console.log("CallConnected: ");
	setShowCancelButton(true);
	sendVideo(true);
	showRemoteVideo(true);
	setCallStatus('active');
}


export function onCallDisconnected(currentCall: Call | null | undefined, setCurrentCall: Function, setShowCancelButton: Function, setCallStatus:Function, setOnlineStatus: Function) {
	console.log(`CallDisconnected`);
	setOnlineStatus('offline');
	setCurrentCall(null);
	setShowCancelButton(false);
	setCallStatus('hangup')

	
	
}
export function onCallFailed(setCurrentCall: Function,setCallStatus: Function, setShowCancelButton: Function, setCallFailed:Function, createCall: Function) {
    console.log(`Не удалось установить соединение `);
    setCurrentCall(null);
    setShowCancelButton(false);
	setCallFailed(true);
	setCallStatus("failed");

    const callButton = document.getElementById("callButton");
    if (callButton) {
        callButton.outerHTML = '<button type="button" className="btn btn-success" id="callButton">Call</button>';
        callButton.remove();
        const newCallButton = document.getElementById("callButton");
        if (newCallButton) {
            newCallButton.addEventListener("click", () => {
                createCall();
            });
		}}}


export const handleInfoReceived = (e: any,setMicStatus: Function) => {
	const receivedMessage = e.body; 
	if (receivedMessage === 'micEnabled' || receivedMessage === 'micDisabled') {
	  setMicStatus(receivedMessage); 
	}
  };
