import { Call } from "voximplant-websdk/Call/Call";
let isFullScreen = false;

export const micDisable = (currentCall: Call | null | undefined, setMicState: Function) => {
	currentCall?.muteMicrophone();
	currentCall?.sendInfo("text/plain",'micDisabled');
	setMicState(false);
};

export const micEnable = (currentCall: Call | null | undefined, setMicState: Function) => {
	currentCall?.unmuteMicrophone();
	currentCall?.sendInfo("text/plain",'micEnabled');
	setMicState(true);
};
export const shareDisable = (currentCall: Call, setShare: Function) => {
	currentCall?.stopSharingScreen();
	console.log("disable");
	setShare(false);
};
export const shareEnable = (currentCall: Call, setShare: Function) => {
	currentCall?.shareScreen(true);
	console.log("enable");
	setShare(true);
};
export const cameraDisable = (
	showLocalVideo: Function,
	sendVideo: Function,
	setCamera: Function,
) => {
	showLocalVideo(false);
	sendVideo(false);
	setCamera(false);
};
export const cameraEnable = (
	showLocalVideo: Function,
	sendVideo: Function,
	setCamera: Function,
) => {
	showLocalVideo(true);
	sendVideo(true);
	setCamera(true);
};
export function isCameraEnabled(currentCall: Call) {
	return currentCall?.sendVideo;
}


export function handleFullScreenButtonClick() {
	const element = document.getElementById("voximplant_container");
	if (element) {
		if (isFullScreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
			isFullScreen = false;
		} else {
			if (element.requestFullscreen) {
				element.requestFullscreen();
			}
			isFullScreen = true;
		}
	}
}
