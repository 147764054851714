import { voxImplantApi } from "./redux/api";
import { configureStore } from "@reduxjs/toolkit";

import { persistReducer, persistStore } from "redux-persist";
//import storage from 'redux-persist/lib/storage';

export const store = configureStore({
	reducer: {
		[voxImplantApi.reducerPath]: voxImplantApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(voxImplantApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
