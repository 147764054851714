import { Button, Image, Typography } from 'antd';
import styles from './styles.module.scss';
import MorePoints from "src/assets/more-points.svg";

type MobileChatProps = {
    openChat: () => void;
    tmkHumanFriendlyId: string;
}

const MobileChat: React.FC<MobileChatProps> = ({
    openChat,
    tmkHumanFriendlyId
}) => {
    return <Button id="chat-mobile-btn" type="text" className={styles.MobileChat} onClick={openChat}>
        <Typography className={styles.OpenChatBtn}>Чат заявки {tmkHumanFriendlyId?.length > 0 ? tmkHumanFriendlyId : ''}</Typography>
        <Image src={MorePoints} preview={false}></Image>
    </Button>;
}

export default MobileChat;
