import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import styles from './styles.module.scss';
import {CustomButton} from "../CustomButton";

const NotOnlineNoteToPatient = () => {
    const closeVcs = () => {
        window.top?.postMessage('CloseVcs', '*');
    }
    return <p className={styles.NoteWrapper}>
        <LoadingAnimation />
        <p className={styles.Title}>Связь прервалась</p>
        <p className={styles.NoteParagraph}>
            <p className={styles.NoteParagraphStart}>Если консультация завершена: </p>
            Mila пришлет уведомление, когда заключение от{'\u00A0'}врача будет готово. 
            Пожалуйста, оцените консультацию в Mila.
        </p>
        <p className={styles.NoteParagraph}>
            <p className={styles.NoteParagraphStart}>Если вы не завершили консультацию: </p>
            ожидайте повторного звонка врача или напишите ему в{'\u00A0'}чат.
        </p>
        <div className={styles.CloseButtonContainer}>
        <CustomButton className={styles.CloseButton} onClick={closeVcs} isPrimal={true}><div>Вернуться в Личный кабинет</div></CustomButton>
        </div>
    </p>;
}

export default NotOnlineNoteToPatient;