import { ConfigProvider } from "antd";

import dayjs from "dayjs";
import isYesterday from "dayjs/plugin/isYesterday";
import ruRU from "antd/lib/locale/ru_RU";
import updateLocale from "dayjs/plugin/updateLocale";

import VoxImplantComponent from "../components/VoxImplantComponent";
import "./App.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

dayjs.locale("ru");
const routes = [
	{
		path: "/mila_vcs/:UUID",
		index: true,
		element: (
			// <div id="vertical-center" className="jumbotron vertical-center">
				<div className="container">
					

					<VoxImplantComponent />
				</div>
			// </div>
		),
	},
];
const router = createBrowserRouter(routes);

function App() {
	dayjs.extend(updateLocale);
	dayjs.extend(isYesterday);

	dayjs.updateLocale("ru", {
		monthsShort: [
			"Янв.",
			"Февр.",
			"Марта",
			"Апр.",
			"Мая",
			"Июня",
			"Июля",
			"Авг.",
			"Сент.",
			"Окт.",
			"Нояб.",
			"Дек.",
		],
	});

	return (
		<>
			<ConfigProvider
				locale={ruRU}
				theme={{
					token: {
						colorPrimary: "#7F49DE",
						colorText: "#15181F",
						colorInfo: "#15181F",
						fontFamily: "Inter",
					},
					hashed: false,
				}}
			>
				<RouterProvider router={router} />
			</ConfigProvider>
		</>
	);
}

export default App;
